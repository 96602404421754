import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Checkbox from '../components/Checkbox.component'
import '../components/all.sass'

const documentStatuses = ['New', 'Use Existing', 'N/A']
export default function DesignPlanFormTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const { tasks } = frontmatter
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]

  return (
    <Layout title={documentName} filePath={filePath}>
      <div className="has-text-centered">
        Project Design Plan
        <div className="is-size-2">
          Engineering Project: {frontmatter.projectId}
        </div>
        See DHF for Document Numbers: {frontmatter.DHFId}
      </div>
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-7">
        <thead>
          {frontmatter.columnTitles.map((title) => (
            <th key={title}>{title}</th>
          ))}
        </thead>
        {tasks.map(({ name, activity }) => (
          <tbody>
            <tr key={name}>
              <td rowSpan={activity.length + 1}>{name}</td>
            </tr>

            {activity.map((task) => (
              <tr>
                <td>{task.name}</td>
                <td>
                  {Object.keys(task.responsibleParty).map((party) => (
                    <span>
                      <Checkbox checked={task.responsibleParty[party]} />
                      &nbsp;{party.replace('_', '/')}&emsp;
                    </span>
                  ))}
                </td>
                <td>{task.outputDocument}</td>
                <td>
                  {task.documentStatus !== 'DO_NOT_SHOW' &&
                    documentStatuses.map((status) => (
                      <span>
                        <Checkbox checked={status === task.documentStatus} />
                        &nbsp;{status}&emsp;
                      </span>
                    ))}
                  {task.because && (
                    <span>&nbsp;because: {task.because}&emsp;</span>
                  )}
                </td>
              </tr>
            ))}
            <tr className="is-selected">
              {frontmatter.columnTitles.map(() => (
                <td></td>
              ))}
            </tr>
          </tbody>
        ))}
      </table>
      <div className="is-size-4">Personnel</div>
      <table className="table is-borderless">
        {frontmatter.personnel.map((group) => (
          <tr>
            <td>{group.group}:</td>
            <td>
              {group.name ? (
                <span>{group.name}</span>
              ) : (
                <span>______________</span>
              )}
            </td>
          </tr>
        ))}
      </table>
      <h3>Description of Activities</h3>
      <dl className="content">
        <dd>
          <b>Design and Development Planning</b>
          <dt className="content">
            <dd>
              Can include: Aspects relating to Plan for Quality of product
              (Quality Plan)
              <dd>
                Suppliers, Procedures, Inspection, Work Instructions, Resource
                Allocation, Training, Equipment, Facility, Storage,
              </dd>
              <dd>
                Environment, Process Validation, Sterilization, Hazard and Risk
                Planning
              </dd>
              <dd>
                Results will be incorporated into Project Design Plan (This
                document)
              </dd>
            </dd>
          </dt>
          <b>Establish Design Requirements</b>
          <dt className="content">
            <dd>
              Design requirements relate back to the Marketing Requirements,
              Patient Requirements, and User Requirements
              <dd>User/Patient/Marketing Requirements as needed</dd>
              <dd>
                User/Patient/Marketing Preference Requirements (consider
                complaint trend reports)
              </dd>
              <dd>User/Patient/Marketing Interface Requirements</dd>
              <dd>User/Patient/Marketing Environmental Requirements</dd>
            </dd>
          </dt>
          <b>Review Related Intellectual Property</b>
          <dt className="content">
            <dd>
              Related to intellectual property that has been published or
              applied for. Review is conducted on USPTO.GOV website and online
            </dd>
          </dt>
          <b>Develop Risk Management Plan</b>
          <dt className="content">
            <dd>
              Plan for Hazard and Risk assessment over the life of the product.
              <dd>Hazard Analysis</dd>
            </dd>
          </dt>
          <b>Establish Design Specifications</b>
          <dt className="content">
            <dd>
              Development of Specifications to meet the Marketing, Patient, and
              User Requirements above (examples if applicable)
              <dd>Electrical Specifications</dd>
              <dd>Mechanical Specifications</dd>
              <dd>Systems Interface Specifications</dd>
              <dd>Physical Specifications</dd>
              <dd>Chemical Specifications</dd>
              <dd>Environmental Specifications</dd>
              <dd>Safety Specifications</dd>
              <dd>Software Specifications</dd>
              <dd>Storage (including shelf life) Specifications</dd>
              <dd>Packaging Specifications</dd>
              <dd>Sterilization Specifications</dd>
              <dd>Create Verification and Validation Specifications</dd>
            </dd>
          </dt>
          <b>Regulatory Requirements</b>
          <dt className="content">
            <dd>
              Determination of a Significant or Non-significant Change
              (including rationale)
            </dd>
            <dd>510(k) Filing Requirements</dd>
            <dd>
              MDR Filing Requirements (MDR Sec 32 and 34) and CAMCAS
              Requirements
            </dd>
            <dd>Animal Testing Requirements</dd>
            <dd>Biocompatibility Requirements</dd>
            <dd>Documentation Requirements</dd>
            <dd>Standards Requirements</dd>
            <dd>Labeling Requirements</dd>
            <dd>
              Risk Analysis/FMECA (Risk Management/Hazard and Risk Analysis
              Requirements)
            </dd>
          </dt>
          <b>Identify Design Materials</b>
          <dt className="content">
            <dd>
              Using Requirements and Specification determine Materials used in
              Product
            </dd>
          </dt>
          <b>Create Working Drawings</b>
          <dt className="content">
            <dd>
              Using Requirements and Specification Create detailed drawings
            </dd>
          </dt>
          <b>Complete FMECA</b>
          <dt className="content">
            <dd>Using Hazard analysis and related documents create FMECA</dd>
          </dt>
          <b>Identify Vendors</b>
          <dt className="content">
            <dd>
              Select Vendors to be used in project. See if we are using existing
              or new vendors. See if vendors need certification for new process
            </dd>
          </dt>
          <b>Perform Verification Tests</b>
          <dt className="content">
            <dd>
              Perform testing based on design specifications. Design
              Verification Documents may include, but are not limited to:
              <ul>
                <li>Functional Test Data</li>
                <li>Biological, Physical and Chemical Laboratory Data</li>
                <li>Contract/Consultant Studies</li>
                <li>Software Validation Data</li>
                <li>Standards Testing</li>
                <li>Standards or Test Certifications from the manufacturer</li>
                <li>Safety Documentation</li>
                <li>Sterility Testing (if applicable)</li>
                <li>Shelf Life Testing</li>
              </ul>
            </dd>
          </dt>
          <b>Perform Validation Tests</b>
          <dt className="content">
            <dd>
              Once the design output has been verified by the Design Review Team
              to meet the design input requirements (design specifications), the
              device will be validated to ensure the device meets its intended
              use and user requirements (design requirements) and may include
              clinical validation.
            </dd>
          </dt>
          <b>Complete Instructions for Use</b>
          <dt className="content"></dt>
          <b>Establish Quality Control Plan</b>
          <dt className="content">
            <dd>
              Using all the inputs and outputs of the product design a plan is
              generated which identifies the process control points required to
              maintain quality during routine production. This Quality Control
              Plan may identify procedures, work instructions, record forms,
              process validation requirements, sterilization requirements,
              equipment and facility requirements, personnel requirements,
              packaging and labeling requirements, test requirements,
              environmental requirements, material ordering requirements and
              supplier requirements.
            </dd>
          </dt>
          <b>Create Device Master Record</b>
          <dt className="content">
            <dd>
              Device Master Record form for each part number to be produced from
              design
            </dd>
          </dt>
          <b>Establish Required Resources</b>
          <dt className="content">
            <dd>
              Pull required resources from quality plan for implementation prior
              to transfer to DMR
            </dd>
          </dt>
        </dd>
      </dl>
    </Layout>
  )
}

export const query = graphql`
  query designPlanFormQuery($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        projectId
        DHFId
        columnTitles
        tasks {
          name
          activity {
            name
            responsibleParty {
              Eng
              Q_A
              Marketing
            }
            outputDocument
            documentStatus
            because
          }
        }
        personnel {
          group
          name
        }
      }
    }
  }
`
const alternativeProps = ['', 'DO_NOT_SHOW']
DesignPlanFormTemplate.propTypes = {
  frontmatter: PropTypes.shape({
    tasks: PropTypes.shape({
      activity: PropTypes.shape({
        documentStatus: PropTypes.oneOf(
          ...documentStatuses,
          ...alternativeProps
        ),
      }),
    }),
  }),
}
